.community {
    background-color: white;
    margin: 10px 0px;

    svg {
        width: 128px !important;
        height: 128px !important;
        color: #62CA50 !important;
    }
    
    .getTraining {
        padding: 40px 0px;
        background-color: #FFFDFA;
        display: flex;
    justify-content: center;
    
        @media screen and (max-width: 768px) {
            padding: 0;
        }
    
        .scheduleCallButton {
            background: #F99C38;
            color: #3F2203;
            font-size: 18px;
            font-weight: 600;
            padding: 12px 28px;
        }
    }

    .whatsOnTitle {
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
        text-align: center;
        margin-bottom: 20px;
        color: #3F2203;

        @media screen and (max-width: 768px) {
            font-size: xx-large;
            line-height: 29px;
            margin-bottom: 25px;
        }
    }

    .column {
        padding: 10px;

        .container {
            width: 100%;
            // aspect-ratio: 1/1;
            padding: 50px 0px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 45px;
            transition: 0.3s;
            border-radius: 8px;

            .iconContainer {
                background-color: #FFFFFF;
                padding: 25px;
                border-radius: 8px;

                i {
                    font-size: 50px;
                }
            }

            .iconName {
                font-weight: 600;
                font-size: 24px;
                line-height: 24px;
            }
        }

        .eventsCard {
            background-color: #FAEDDC;

            &:hover {
                background-color: rgba(223, 136, 20, 1);
                color: white;
            }
        }

        .privateGroupCard {
            background-color: #E2ECFE;

            &:hover {
                background-color: rgba(60, 129, 246, 1);
                color: white;
            }
        }

        .jobsCard {
            background-color: #F0E5FE;

            &:hover {
                background-color: rgba(157, 85, 255, 1);
                color: white;
            }
        }

        .botCard {
            background-color: #F4DFD9;

            &:hover {
                background-color: rgba(179, 40, 1, 1);
                color: white;
            }
        }
    }

    .peopleFromText {
        font-weight: 700;
        font-size: 32px;
        line-height: 43px;
        text-align: center;
        margin-bottom: 20px;
    }

    .brandShowcase {
        .brands {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: wrap;

            .brand {
                height: 100px;
                width: 210px;
                border-radius: 8px;
                border: 1px solid grey;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
            }

            img {
                max-height: 90px;
                max-width: 190px;
            }
        }
    }
}