.popularQuestions {
    padding: 20px 0px;

    @media screen and (max-width: 768px) {
        padding-top: 20px;
    }

    .title {
        margin-bottom: 50px;
        font-weight: 700;
        font-size: 50px;
        line-height: 58px;
        text-align: center;

        @media screen and (max-width: 768px) {
            font-size: xx-large;
            margin-bottom: 20px;
        }
    }

    a {
        color: rgb(63, 34, 3);
        font-weight: 400;
    }

    .questionsContainer {
        padding: 10px 19px;
        font-size: 16px;

        .question {
            opacity: 0.9;
            display: flex;
            background: #FFFFFF;
            color: rgba(63, 34, 3, 1);
            box-shadow: 0px -7px 104px -20px rgba(0, 0, 0, 0.2);
            padding: 15px;
            border-radius: 8px;
            transition: 0.3s ease-in-out;

            &:hover {
                opacity: 1;
                transform: scale(1.05);
                color: #B32800 !important;
            }

            a:hover {
                color: #B32800 !important;
            }

            .content {
                padding: 0px 10px;
                height: 110px;
                // position: relative;

                .actions {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                    // position: absolute;
                    // bottom: 0;

                    .partOne {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .actionIcon {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 5px;
                        }

                        .hashtag {
                            background-color: #F8E9D5;
                        }
                    }

                    .partTwo {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }
            }
        }
    }
}