.socialProfiles {
    margin-top: 15px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.footer {
    background-color: #F8E9D5;
    color: #615249;
    padding-top: 30px;
    padding-bottom: 16px;

    .logoss {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .description {
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
    }

    .quickLinks {
        margin-top: 20px;
        text-align: center;

        p {
            margin: 0px;
        }
    }

    .hr {
        margin-top: 36px;
        background-color: rgba(97, 82, 73, 1);
        opacity: 0.4;
    }

    .copyrightText {
        text-align: center;
        font-weight: bold;
    }
}