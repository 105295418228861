.parse-text {
  white-space: pre-wrap;
}

.ant-drawer-inline {
  position: fixed !important;
}

.question-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-number svg {
  font-size: 28px;
}

.active-question {
  background-color: #F8E9D5;
  border-radius: 8px;
  color: #615249;
  font-size: 18px;
}

.MuiStepLabel-root {
  padding: 7px 10px !important;
}

.task-men {
  position: inherit !important;
}

.task-men .MuiDialog-container {
  min-height: 100vh !important;
}

.quiz {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.answer-field {
  width: 100% !important;
}

.clock {
  display: flex;
  font-size: 20px;
  font-weight: 400;
  margin-right: 25px;
}

.clock-time div{
  margin-right: 20px;
  color: #3F2203;
  padding: 7px;
  font-weight: 600;
  float: right;
  align-items: center;
  border: 1px solid #000000;
  display: flex;
  border-radius: 8px;
}

.top-quiz-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ques-tags {
  font-size: 14px;
  color: #000;
}

.scoring {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.scoring p {
  color: #000000ba !important;
}

.show-all {
  text-align: left;
  padding: 0px;
}

.show-ques {
  font-size: 18px;
  font-weight: 500;
  white-space: normal !important;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #FFFDFA;
  border-radius: 8px;
  display: flex;
  border: 3px solid transparent;
  background-clip: content-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4px 0px;
}

::-webkit-scrollbar-thumb {
  background: #452e21;
  border-radius: 8px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.googleBtn {
  background-color: #dc4e41;
  width: 300px;
  cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 20px;
    padding: 16px;
    align-items: center;
    font-weight: 600;
    border: none;
    margin-left: auto;
    margin-right: auto;
    color: white;
}

.flex-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.flex-space-info {
  padding: 2px 0px 25px 5px;
  color: gray;
  border-bottom: 2px solid lightgrey;
}

.applied-info {
  color: gray;
  font-size: 14px;
  margin-top: -8px !important;
  margin-bottom: 0px !important;
}

.applied-info2 {
  color: gray;
  font-size: 14px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.flex-space-info2 {
  padding: 2px 0px 5px 5px;
  color: gray;
}

.flex-center {
  display: flex;
  align-items: center;
}

.info-divs {
  margin-top: 20px;
}

.info-divs p {
  color: #5f6c7b;
}

.btn-black {
  background-color: #000 !important;
  border-radius: 24px !important;
  color: #fff !important;
}

.googleBtn img {
  width: 20px;
    height: 20px;
    padding: 2px;
    margin: 0px 5px;
    vertical-align: middle;
}

.login {
  background-image: url('https://recruitingmonk.azurewebsites.net/wp-content/uploads/2020/03/Profile-back.png') !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.login {
  justify-content: center;
  align-content: center;
  height: 60vh;
  background:white;
  text-align: center;
}

.button-default {
  background-color: #b32800 !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.head {
font-size: 28px;
}

.name {
  margin-top: 4px;
  font-size: 20px !important;
}

.logo {
  width: 160px;
  height: 50px;
  float: left;
  margin-left: 20px;
}

.logout {
  padding-right: 20px; 
}

#job-form label {
  font-weight: 500;
}

#job-form h2 {
  display: flex;
  align-items: center;
}

.flex-it {
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
  height: 80vh;
  align-items: center;
}

.start-button {
  background: #F8E9D5 !important;
border-radius: 8px !important;
font-family: 'Roboto' !important;
font-weight: 400 !important;
text-transform: capitalize !important;
font-size: 18px !important;
line-height: 28px !important;
color: #615249 !important;
float: right;
}

.button-default-light {
  background-color: #fff !important;
  border: 1px solid #3F2203 !important;
border-radius: 8px !important;
box-shadow: none !important;
color: #3F2203 !important;
text-transform: capitalize !important;
}

.same-text-p {
  color: #615249;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button {
  color: #3F2203 !important;
  background: #FFF !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}

.start-button2 {
  color: #3F2203 !important;
  background: #F99C38 !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}

.flex-it p {
  font-size: 20px;
  font-weight: 400;
  font-family: 'Roboto';
}

.card-div {
  top: 10%;
  border-radius: 8px;
  padding: 24px 48px 24px 48px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card-div h3 {
  color: #3F2203 !important;
}

.text-log {
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  font-family: 'Roboto';
  margin-left: 5px;
}

.links {
  text-align: center;
  text-decoration: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.round {
  border-radius: 28px !important;
  text-transform: capitalize !important;
  height: 40px !important;
  width: 180px !important;
  margin-top: 30px !important;
  margin-bottom: 20px !important;
  font-size: 17px !important;
}

.white-button {
  background-color: #fff !important;
  color: #000 !important;
}

.grad-button {
  background: repeating-linear-gradient(45deg, #F99C38, #B32800);
  margin-left: 40px !important;
}

@media only screen and (max-width: 767px) {
  .fbBtn, .googleBtn {
    width: 261px;
  }
}


/* bar graphs */
.bar-graph .year {
  opacity: 1;
}

.bar-graph-horizontal > div {
  float: left;
  margin-bottom: 8px;
  width: 100%;
}

.bar-graph-horizontal .year {
  float: left;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  width: 80px;
}

.bar-graph-horizontal .bar {
  border-radius: 3px;
  height: 40px;
  float: left;
  overflow: hidden;
  position: relative;
}

.bar-graph .bar::after {
  color: #fff;
  content: attr(data-percentage);
  font-weight: 700;
  position: absolute;
  right: 16px;
  top: 10px;
}

.bar-graph .bar-one .bar {
  background-color: #64b2d1;
}

.bar-graph .bar-two .bar {
  background-color: #5292ac;
}


/* mobile CSS */
@media only screen and (max-width: 990px) {
  .logo {
    width: 100px;
    height: 35px;
    float: left;
    margin-left: 5px;
  }

  .round {
    border-radius: 18px !important;
    width: 40% !important;
    margin-top: 20px !important;
    font-size: 14px !important;
  }
  
  .grad-button {
    margin-left: 15px !important;
    width: 100% !important;
  }

  .name {
    font-size: 14px !important;
  }
  
  .logout {
    padding-right: 5px; 
  }

  .logout img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .logout button {
    padding: 2px 8px !important;
  }

  .comp-logo img {
    height: 30px !important;
  }

  .comp-logo p {
    font-size: 12px !important;
  }

  .comp-logo h2 {
    font-size: 14px !important;
  }

  .btn-black {
    padding: 4px 10px !important;
    font-size: 12px !important;
  }

  .flex-center, .flex-space-info, .flex-space-info2, .text-log {
    font-size: 12px !important;
  }

  .ant-modal-body {
    padding: 8px !important;
  }

  .flex-it p {
    font-size: 14px;
  }

  .card-div {
    padding: 10px !important;
    margin: 10px !important;
  }

  .start-button, .back-button, .start-button2 {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .same-text-p {
    font-size: 11px !important;
  }

  .qz-menu {
    width: 100px !important;
    font-size: 12px;
  }
}