.main {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	gap: 2.5rem;

	.relative {
		display: flex;
		position: relative;

		.flex-col {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			margin-left: 2rem;

			img {
				width: 97px;
				height: 97px;
			}
		}

		.w-full {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;

			.flex-col {
				display: flex;
				flex-direction: column;
				gap: 3rem;
				align-items: center;

				p {
					font-size: 1.875rem;
					color: #3f2203;

					font-weight: 600;
					text-align: center;
				}

				.flex {
					display: flex;
					gap: 1rem;
				}
				button {
					margin-left: auto;
					border: 1px solid #e5e7e8;
					padding: 0.5rem 1rem;
					border-radius: 0.5rem;
				}
			}
		}
	}
}

.roundedDiv {
	border-radius: 0.5rem;
	padding: 0;
	border: 1px solid #e5e7e8;
	font-size: 1.25rem;
	background: #fff;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
}
